export const TOKENIZED_SECURITY_CODE_COMPONENT_NAME = "securityCodeTokenized";
export const TOKENIZED_SECURITY_CODE_IFRAME =
  "st-security-code-tokenized-iframe";
export const TOKENIZED_SECURITY_CODE_INPUT_ID =
  "st-security-code-tokenized-input";
export const TOKENIZED_SECURITY_CODE_INPUT_SELECTOR =
  "st-security-code-tokenized";
export const TOKENIZED_SECURITY_CODE_MESSAGE =
  "st-security-code-tokenized-message";
export const TOKENIZED_SECURITY_CODE_LABEL = "st-security-code-tokenized-label";
export const TOKENIZED_SECURITY_CODE_WRAPPER =
  "st-security-code-tokenized-wrapper";

export const TOKENIZED_SECURITY_CODE_DISABLED_CLASS = "st-input--disabled";

export const TOKENIZED_SECURITY_CODE_PATTERN = "^[0-9]{3,4}$";
export const TOKENIZED_SECURITY_CODE_LENGTH = 4;
