import { Observable, of } from "rxjs";
import { Service } from "typedi";
import { IFraudControlDataProvider } from "../../services/fraud-control/IFraudControlDataProvider";

@Service()
export class SeonFraudControlDataProviderMock
  implements IFraudControlDataProvider
{
  init(): Observable<undefined> {
    return of(undefined);
  }

  getTransactionId(): Observable<string | null> {
    return of(
      "Web;1b598e0a-511d-4183-b2a9-15096b32071e;DWqjQC8QD75N+UBOV3z6oA==;AnBqDCCG69GhW1u6WM9zPWwVL6/VWyEUkaRIrGHRcJUQ7+J28f0UY3TQtj6mQiHWUjkDuju45rW8eDFEUYWgfoTMFIWHoHoqV4EkHYyU7X1/o3Mf8fd6P8Hf9qBPl93A1K3hN/4H8UbrbEIv54cdsZPCptoqs17zS2QAkJhMMiBqe5JONbbvMsKAATWeOvZuEYHH3UubsgHAqfr4LG5moEPsGokWk31tC5ZYg+r0iA/UgITwqIeIWtW7IeOKNIDBiBgLCy5vFPDjYl+Iem+91W70TbuvP3xvURv3IKKP1+uheuY6QHH5jgjXDCaKrGgkbp8YikNcP9EQWe/OTcK2/nCYTaodnjQoQFsBM4CBgbzyIslZgeBbOW26Oyrmq892OGrreBRU5G/PT+ZDKLJkQw9UTcRuDfCsPuE7iaFeV/fPNUMqk+qDikK1F7mEZLi9J5gfc1IUmuvzl/HproqQrFDZ746ZyBrDtu53yILZla5E5gdr01SUZcOc6NdKtZApUYs0Qr6qX0tWgAW7uJWW+2HdlfyEDAopyUuwIOC5qcwWOkKA9lV60mqtwIMfivuWmd3blKROg/+wKhqdcLnrJb47ctrp2PoV2Rs+1Jr8R/7FYDd1UaPeZfGZRHvnnrfaOknbTOmguBn+13xixluZ8i+z5U6FziOKbJV1bBh33Zu/BfQj4yPQCwhnSaGYnjQ6k+lgHnEf8aU0caHMrIvBtpHfC8brOA0xS5kNFIrl0qMoUkpGpemj/euguNUE2btjgiJ9oFH1j5dGAE/Hoh9bm4iACJQ7zh9XIkikRdNqSp/as1Wj817IBYfA1GZ56ZQyhdhHeQy0H0SAU2fewEHpW11h160GD5yQcu2f6g9sMNJmwmvZldvtm0z3m3UHMqSE+qI6hRcKhJhQsH1jlCdn7iOtMi8fx8Iwrn8Nrd6W1K5jYpbSvDs5srydnGBzPGRVQOyx5icVJ7TIF9Zj/EhAJnbj8krQ9qHRmPELXuR/cjKd4fbznEoXHtR5D6lfQ5zQXeY7XthjQQmY75Tkfmv86+7yLmHM4k52hIyXBFfIPfOvcUIkvkpTTKVNyuEK7QGE5cK7EgtRvyPH/A4rA3KkRYEEDqc0fdMF4mkjcOhiHbzfonQOE2uVMLYmD6BjqpzA9fc/ZHxfjTpIHWDE8BQSlwZ6zpGlWRdVlIIQf8s9+eYo0j+Kuxe1my0DqYgge8DMpfJ1gR+T+cL8RuQUdVCi209AD4HTN4kCeKCHsK7Y+y+j6fqXZoFaiSP2wpx8M2YnLPW+fFFcnhZ420rVIZtFR/mYJsX+h2MykvHyi8KX7iwoRiA7tQEQ2xpD75t2Q5pVlZmK2ECUs3KdrKswrbHCBJHr0b2loaVNds9g0l089ZA7qS1zEX6YWJCPeRuUITL+6r5EqRwsoShmejTqHezKwHvCTrHx4Z9L+dcSLjKQ2shsuDlxT8xq/HXhN158jvDJ3CoGrKbqukQ/4+KaKTcZKxcuwXjRF9V9+JWp8sX17d/5ZaufWZxw1HjmRsTU5SP+XZcZDs+Y6fXIxaFoL2JWOuJv42I4aLE1yWNvWiRD54YgBoYuUS4Q0OdPh8RoRn1x3XZj2YQb0gWiwWJORaqVJyIwBvm9lvDVPUbFmJwk4u+VFGdkjrekDjX2mCn1vDfoCGXkmG7pJ3dsRV78t9VGVvowI05T8Nr05AiOH5sM21IyJHxWemWzn0UWmB7EjUtKBWCVfSwEFQmM8UCIMcdS3x2gK0xqJcN6VoO2+Sif0U4tgrnugUFqVEtvZlaQpDkUceddseBALzr3haW1Tv4R7wt8JPsqxxQ89VHPdHK5XIqAkeMlSf9pFblaNkFy5ie9"
    );
  }
}
