export enum APMPayloadFields {
  billingcountryiso2a = "billingcountryiso2a",
  currencyiso3a = "currencyiso3a",
  billingdob = "billingdob",
  orderreference = "orderreference",
  accounttypedescription = "accounttypedescription",
  baseamount = "baseamount",
  mainamount = "mainamount",
  requesttypedescriptions = "requesttypedescriptions",
  sitereference = "sitereference",
  billingfirstname = "billingfirstname",
  billinglastname = "billinglastname",
  billingpremise = "billingpremise",
  billingstreet = "billingstreet",
  billingtown = "billingtown",
  billingpostcode = "billingpostcode",
  billingcounty = "billingcounty",
  billingemail = "billingemail",
}
