import { forkJoin, from, Observable, ObservableInput } from "rxjs";
import { first, map, tap } from "rxjs/operators";

/**
 * returns Observable with array of first value emitted by each source observable
 * values in array are sorted in order source observables emit their respective values
 * @param sources
 */
export function forkJoinInOrder<T>(
  sources: ObservableInput<T>[]
): Observable<T[]> {
  const results: T[] = [];

  const mappedSources = sources.map((source) => {
    return from(source).pipe(
      first(),
      tap((response: T) => results.push(response))
    );
  });

  return forkJoin(mappedSources).pipe(map(() => results));
}
