import { Observable, switchMap } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Service } from "typedi";
import { ISrcProvider } from "../../ISrcProvider";
import { ISrc } from "../../ISrc";
import { ConfigProvider } from "../../../../../shared/services/config-provider/ConfigProvider";
import { environment } from "../../../../../environments/environment";
import { DomMethods } from "../../../../../application/core/shared/dom-methods/DomMethods";
import { SrcName } from "../../SrcName";
import { SrcProviderToken } from "../../../../../client/dependency-injection/InjectionTokens";
import { SentryService } from "../../../../../shared/services/sentry/SentryService";
import { MastercardSrcWrapper } from "./MastercardSrcWrapper";

@Service({ id: SrcProviderToken, multiple: true })
export class MastercardSrcProvider implements ISrcProvider {
  private src: Observable<ISrc>;

  constructor(
    private configProvider: ConfigProvider,
    private sentryService: SentryService
  ) {
    this.src = this.configProvider.getConfig$().pipe(
      map((config) =>
        config.livestatus
          ? environment.CLICK_TO_PAY.MASTERCARD.PROD
          : environment.CLICK_TO_PAY.MASTERCARD.SANDBOX
      ),
      switchMap((sdkConfig) =>
        DomMethods.insertScript("head", { src: sdkConfig.SRC_SDK_URL }).pipe(
          this.sentryService.captureAndReportResourceLoadingTimeout(
            "Mastercard To Pay SDK script load timeout",
            environment.CLICK_TO_PAY.SCRIPT_LOAD_TIMEOUT
          ),
          map(() => new MastercardSrcWrapper(sdkConfig.SRC_INITIATOR_ID))
        )
      ),
      shareReplay(1)
    );
  }

  getSrcName(): SrcName {
    return SrcName.MASTERCARD;
  }

  getSrc(): Observable<ISrc> {
    return this.src;
  }
}
