export enum ErrorTypeName {
  Error = "Error",
  GatewayError = "GatewayError",
  TimeoutError = "TimeoutError",
  RequestTimeoutError = "RequestTimeoutError",
  CardinalError = "CardinalError",
  MisconfigurationError = "MisconfigurationError",
  PaymentError = "PaymentError",
  FrameCommunicationError = "FrameCommunicationError",
  GatewayFetchError = "GatewayFetchError",
}
