export const DefaultSubmitFields: string[] = [
  "baseamount",
  "currencyiso3a",
  "eci",
  "enrolled",
  "errorcode",
  "errordata",
  "errormessage",
  "orderreference",
  "settlestatus",
  "status",
  "transactionreference",
  "formId",
];
