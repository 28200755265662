import { environment } from "../../../../environments/environment";

export const CARD_NUMBER_COMPONENT_NAME = "cardNumber";
export const CARD_NUMBER_IFRAME = "st-card-number-iframe";
export const CARD_NUMBER_INPUT = "st-card-number-input";
export const CARD_NUMBER_INPUT_SELECTOR = "st-card-number";
export const CARD_NUMBER_MESSAGE = "st-card-number-message";
export const CARD_NUMBER_LABEL = "st-card-number-label";
export const CARD_NUMBER_WRAPPER = "st-card-number__wrapper";

export const EXPIRATION_DATE_COMPONENT_NAME = "expirationDate";
export const EXPIRATION_DATE_IFRAME = "st-expiration-date-iframe";
export const EXPIRATION_DATE_INPUT = "st-expiration-date-input";
export const EXPIRATION_DATE_INPUT_SELECTOR = "st-expiration-date";
export const EXPIRATION_DATE_MESSAGE = "st-expiration-date-message";
export const EXPIRATION_DATE_LABEL = "st-expiration-date-label";
export const EXPIRATION_DATE_WRAPPER = "st-expiration-date__wrapper";

export const SECURITY_CODE_COMPONENT_NAME = "securityCode";
export const SECURITY_CODE_IFRAME = "st-security-code-iframe";
export const SECURITY_CODE_INPUT = "st-security-code-input";
export const SECURITY_CODE_INPUT_SELECTOR = "st-security-code";
export const SECURITY_CODE_MESSAGE = "st-security-code-message";
export const SECURITY_CODE_LABEL = "st-security-code-label";
export const SECURITY_CODE_WRAPPER = "st-security-code__wrapper";

export const NOTIFICATION_FRAME_ID = "st-notification-frame";
export const NOTIFICATION_FRAME_CORE_CLASS = "notification-frame";
export const NOTIFICATION_FRAME_ERROR_CLASS = "notification-frame--error";
export const NOTIFICATION_FRAME_INFO_CLASS = "notification-frame--info";
export const NOTIFICATION_FRAME_SUCCESS_CLASS = "notification-frame--success";
export const NOTIFICATION_FRAME_CANCEL_CLASS = "notification-frame--cancel";

export const CONTROL_FRAME_COMPONENT_NAME = "controlFrame";
export const CONTROL_FRAME_IFRAME = "st-control-frame-iframe";

export const MERCHANT_FORM_SELECTOR = "st-form";
export const MERCHANT_PARENT_FRAME = "st-parent-frame";

export const CARD_NUMBER_COMPONENT = `${environment.FRAME_URL}/card-number.html`;
export const EXPIRATION_DATE_COMPONENT = `${environment.FRAME_URL}/expiration-date.html`;
export const SECURITY_CODE_COMPONENT = `${environment.FRAME_URL}/security-code.html`;
export const TOKENIZED_SECURITY_CODE_COMPONENT = `${environment.FRAME_URL}/security-code-tokenized.html`;
export const CONTROL_FRAME_COMPONENT = `${environment.FRAME_URL}/control-frame.html`;
export const ANIMATED_CARD_COMPONENT = `${environment.FRAME_URL}/animated-card.html`;

export const ANIMATED_CARD_INPUT_SELECTOR = "st-animated-card";
export const ANIMATED_CARD_COMPONENT_IFRAME = "st-animated-card-iframe";
export const ANIMATED_CARD_COMPONENT_NAME = "animatedCard";
