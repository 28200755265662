import { Subject } from "rxjs";
import { CTPSignInOTP } from "../CTPSignInOTP";
import { ITranslator } from "../../../../../../application/core/shared/translator/ITranslator";
import { IInitiateIdentityValidationResponse } from "../../../../digital-terminal/ISrc";
import {
  codeInputsTemplate,
  headerTemplate,
  submitButtonTemplate,
} from "../CTPSignInUtils";

const logo = require("../../../../../../application/core/services/icon/images/click-to-pay.svg");

export class VisaCTPSignInOTP extends CTPSignInOTP {
  constructor(protected translator: ITranslator) {
    super(translator);
  }

  show(
    validationResponse: IInitiateIdentityValidationResponse,
    resultSubject: Subject<string>,
    resendSubject: Subject<boolean>
  ) {
    super.show(validationResponse, resultSubject, resendSubject);
    this.generateValidationCodeForm();
    this.appendCodeFormAddListeners(resultSubject);

    this.validationCodeForm
      .querySelector("#st-ctp-opt-resend")
      .addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        resendSubject.next(true);
      });
  }

  generateValidationCodeForm() {
    this.validationCodeForm.innerHTML = `
      <div class='st-ctp-prompt__otp-wrapper'>
        ${headerTemplate(this.closeButtonId, logo)}
         <div class="st-hpp-prompt__title">${this.translator.translate(
           "Confirm it's you"
         )}</div>
         <div class="st-hpp-prompt__descrption">${this.translator
           .translate(
             "Enter the code sent to {{validationChannel}} to checkout with Click to Pay."
           )
           .replace("{{validationChannel}}", this.validationChannels.join(","))}
       </div>
      ${codeInputsTemplate(
        this.fieldClass,
        this.errorFieldClass,
        this.otpInputsNames
      )}
       <a class="st-hpp-prompt__link" id="st-ctp-opt-resend">${this.translator.translate(
         "Resend"
       )}</a>
      ${submitButtonTemplate(this.translator.translate("Verify"))}
     </div>`;
  }
}
