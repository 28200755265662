import { Subject } from "rxjs";
import { ITranslator } from "../../../../../application/core/shared/translator/ITranslator";
import { IInitiateIdentityValidationResponse } from "../../../digital-terminal/ISrc";

export class CTPSignInOTP {
  protected readonly errorFieldClass = "st-hpp-prompt__field-error";
  protected readonly fieldClass = "st-ctp-prompt__otp-inputs";
  protected readonly fieldErrorClass = "st-ctp-prompt__otp-inputs--invalid";
  protected readonly closeButtonId = "st-hpp-prompt__otp-close";
  protected readonly fieldName = "st-ctp-code";
  protected container: HTMLElement;
  protected fieldElement: HTMLElement;
  protected errorElement: HTMLElement;
  protected cancelCallback: () => void;
  protected otpInputsNames = new Array(6)
    .fill("")
    .map((value, index) => `${this.fieldName}${index}`);
  validationCodeForm: HTMLFormElement = document.createElement("form");
  wrapperForm: HTMLDivElement = document.createElement("div");
  validationChannels: string[];

  constructor(protected translator: ITranslator) {}

  setContainer(containerId: string) {
    this.container = document.getElementById(containerId);
  }

  showError(errorText: string) {
    this.clearError();
    this.errorElement.innerText = errorText.trim();
    this.fieldElement.classList.add(this.fieldErrorClass);
  }

  protected clearError() {
    this.errorElement.innerText = "";
    this.fieldElement.classList.remove(this.fieldErrorClass);
  }

  close() {
    this.container.innerHTML = "";
  }

  onCancel(callback) {
    this.cancelCallback = callback;
  }

  show(
    validationResponse: IInitiateIdentityValidationResponse,
    resultSubject: Subject<string>,
    resendSubject: Subject<boolean>
  ) {
    this.validationChannels = (
      validationResponse.maskedValidationChannel as string
    ).split(",");
  }

  addEventOnSubmitCodeForm(resultSubject) {
    this.validationCodeForm.addEventListener("submit", (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (this.validationCodeForm.checkValidity()) {
        const otpCode = this.otpInputsNames
          .map((name) => this.validationCodeForm.elements[name]?.value)
          .join("");
        resultSubject.next(otpCode);
      }
    });
  }

  addEventCancelForm(form, closeButtonId) {
    form.querySelector(`#${closeButtonId}`).addEventListener("click", () => {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
    });
  }

  appendCodeFormAddListeners(resultSubject) {
    this.container.innerHTML = "";
    this.wrapperForm.classList.add("st-hpp-prompt");
    this.wrapperForm.appendChild(this.validationCodeForm);
    this.container.appendChild(this.wrapperForm);
    this.otpInputsNames.forEach((value) =>
      this.setInputListener(this.validationCodeForm.elements[value])
    );

    this.errorElement = this.validationCodeForm.querySelector(
      `.${this.errorFieldClass}`
    );
    this.fieldElement = this.validationCodeForm.querySelector(
      `.${this.fieldClass}`
    );
    (
      this.validationCodeForm.querySelector(
        "input:first-of-type"
      ) as HTMLInputElement
    )?.focus();
    this.addEventOnSubmitCodeForm(resultSubject);
    this.addEventCancelForm(this.validationCodeForm, this.closeButtonId);
  }

  protected setInputListener(input: HTMLInputElement) {
    input.addEventListener("beforeinput", (event) => {
      const digitsRegexp = /^\d+/g;
      if (event.data && !digitsRegexp.test(event.data)) {
        event.preventDefault();
        return;
      }

      if (input.value.length > 0) {
        input.select();
      }
    });

    input.addEventListener("input", (event) => {
      this.clearError();
      if (!input.value?.trim()) {
        return true;
      }

      (input.nextElementSibling as HTMLElement)?.focus();
    });

    input.addEventListener("keydown", (event) => {
      if (
        event.code === "Backspace" &&
        (event.target as HTMLInputElement).value === ""
      ) {
        (input.previousElementSibling as HTMLElement)?.focus();
      }
    });

    input.addEventListener("paste", (event) => {
      event.preventDefault();
      const pastedData = event.clipboardData?.getData("text/plain")?.trim();
      const isValidCode =
        typeof pastedData === "string" && /^\d{6}$/.test(pastedData);

      if (isValidCode) {
        this.fillOTPInputs(pastedData);
      }
    });
  }

  protected fillOTPInputs(code: string) {
    const splitCode = code.split("");
    this.otpInputsNames.forEach((fieldName, index) => {
      (
        this.container.querySelector(
          `form input[name="${fieldName}"]`
        ) as HTMLInputElement
      ).value = splitCode[index] || "";
    });
  }
}
