import { omit } from "lodash";
import {
  ICheckoutData,
  ICheckoutResponse,
  ICompleteIdValidationResponse,
  IConsumerIdentity,
  IIdentityLookupResponse,
  IInitiateIdentityValidationResponse,
  IIsRecognizedResponse,
  ISrc,
  ISrcInitData,
  ISrcProfile,
  ISrcProfileList,
  IUnbindAppInstanceResponse,
} from "../../ISrc";

import { SrcName } from "../../SrcName";
import {
  IMastercardConsumerIdentity,
  IMastercardIdentityLookupResponse,
  IMastercardInitiateIdentityValidationResponse,
  IMastercardSrc,
  IMastercardSrcProfile,
  MasterCardIdentityType,
} from "./IMastercardSrc";

export class MastercardSrcWrapper implements ISrc {
  private mastercardSrc: IMastercardSrc;

  constructor(readonly initiatorId: string) {
    //@ts-ignore
    this.mastercardSrc = window.SRCSDK_MASTERCARD;
    this.initiatorId = initiatorId;
  }

  init(initData: ISrcInitData | Partial<ISrcInitData>): Promise<void> {
    const mastercardInitData: Partial<ISrcInitData> = {
      srcInitiatorId: this.initiatorId,
      srciDpaId:
        typeof initData?.srciDpaId === "string"
          ? initData.srciDpaId
          : initData?.srciDpaId[SrcName.MASTERCARD],
    };
    return this.mastercardSrc.init({
      ...initData,
      ...mastercardInitData,
    } as ISrcInitData);
  }

  async checkout(data: ICheckoutData): Promise<ICheckoutResponse> {
    try {
      return {
        ...(await this.mastercardSrc.checkout(data)),
        srcName: SrcName.MASTERCARD,
      };
    } catch (error) {
      if (!error.reason) {
        error.name = SrcName.MASTERCARD;
      }
      throw error;
    }
  }

  completeIdentityValidation(
    validationData: string
  ): Promise<ICompleteIdValidationResponse> {
    return this.mastercardSrc.completeIdentityValidation({ validationData });
  }

  getSrcProfile(idTokens: string[]): Promise<ISrcProfileList> {
    const srcResponse = idTokens
      ? this.mastercardSrc.getSrcProfile({ idTokens })
      : this.mastercardSrc.getSrcProfile();

    return srcResponse.then((response) => {
      return {
        profiles: response.profiles.map((profile) =>
          this.mapSrcProfile(profile)
        ),
        srcCorrelationId: response.scrCorrelationId,
      };
    });
  }

  identityLookup(
    consumerIdentity: IConsumerIdentity
  ): Promise<IIdentityLookupResponse> {
    return this.mastercardSrc
      .identityLookup({
        consumerIdentity: this.consumerIdentityMapper(consumerIdentity),
      })
      .then((identityLookupResponse: IMastercardIdentityLookupResponse) => {
        return omit(identityLookupResponse, "lastUsedCardTimestamp");
      })
      .then((response) => ({
        ...response,
        srcName: SrcName.MASTERCARD,
      }));
  }

  initiateIdentityValidation(
    requestedValidationChannelId?: string
  ): Promise<IInitiateIdentityValidationResponse> {
    return this.mastercardSrc
      .initiateIdentityValidation({ requestedValidationChannelId })
      .then((response: IMastercardInitiateIdentityValidationResponse) => {
        return omit(response, "validationMessage");
      });
  }

  isRecognized(): Promise<IIsRecognizedResponse> {
    return this.mastercardSrc.isRecognized();
  }

  unbindAppInstance(idToken?: string): Promise<IUnbindAppInstanceResponse> {
    return this.mastercardSrc
      .unbindAppInstance()
      .then(({ srcCorrelationId }) => ({ srcCorrelatedId: srcCorrelationId }));
  }

  private consumerIdentityMapper(
    consumerIdentity: IConsumerIdentity
  ): IMastercardConsumerIdentity {
    return omit(
      {
        ...consumerIdentity,
        identityType: MasterCardIdentityType[consumerIdentity.type],
      },
      "type"
    );
  }

  private mapSrcProfile(profile: IMastercardSrcProfile): ISrcProfile {
    return {
      idToken: profile.authorization,
      maskedCards: profile.maskedCards,
      maskedConsumer: {
        emailAddress: profile.maskedConsumer.maskedEmailAddress,
        languageCode: profile.maskedConsumer.languageCode,
        fullName: profile.maskedConsumer.maskedFullName,
        lastName: profile.maskedConsumer.maskedLastName,
        firstName: profile.maskedConsumer.maskedFirstName,
        countryCode: profile.maskedConsumer.countryCode,
        mobileNumber: {
          countryCode: profile.maskedConsumer.maskedMobileNumber?.countryCode,
          phoneNumber:
            profile.maskedConsumer.maskedMobileNumber?.maskedPhoneNumber,
        },
      },
    };
  }
}
