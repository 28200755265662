import {
  NOTIFICATION_FRAME_CANCEL_CLASS,
  NOTIFICATION_FRAME_ERROR_CLASS,
  NOTIFICATION_FRAME_INFO_CLASS,
  NOTIFICATION_FRAME_SUCCESS_CLASS,
} from "../Selectors";

export const NotificationsClasses = {
  error: NOTIFICATION_FRAME_ERROR_CLASS,
  info: NOTIFICATION_FRAME_INFO_CLASS,
  success: NOTIFICATION_FRAME_SUCCESS_CLASS,
  cancel: NOTIFICATION_FRAME_CANCEL_CLASS,
};
