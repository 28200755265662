import { Observable, of, throwError } from "rxjs";
import { Service } from "typedi";
import { environment } from "../../../../environments/environment";
import { SrcName } from "../SrcName";
import { ConfigProvider } from "../../../../shared/services/config-provider/ConfigProvider";
import { IEncryptionKey } from "./IEncryptionKey";

@Service()
export class EncryptionKeyProvider {
  private livestatus: number;

  constructor(private configProvider: ConfigProvider) {
    this.livestatus = this.configProvider.getConfig().livestatus;
  }

  getEncryptionKey(srcName: SrcName): Observable<IEncryptionKey> {
    switch (srcName) {
      case SrcName.VISA:
        return of({
          kid: this.livestatus
            ? environment.CLICK_TO_PAY.VISA.PROD.ENCRYPTION_KID
            : environment.CLICK_TO_PAY.VISA.SANDBOX.ENCRYPTION_KID,
          jwk: this.livestatus
            ? environment.CLICK_TO_PAY.VISA.PROD.JWK
            : environment.CLICK_TO_PAY.VISA.SANDBOX.JWK,
        });
      case SrcName.MASTERCARD:
        return of({
          kid: this.livestatus
            ? environment.CLICK_TO_PAY.MASTERCARD.PROD.ENCRYPTION_KID
            : environment.CLICK_TO_PAY.MASTERCARD.SANDBOX.ENCRYPTION_KID,
          jwk: this.livestatus
            ? environment.CLICK_TO_PAY.MASTERCARD.PROD.JWK
            : environment.CLICK_TO_PAY.MASTERCARD.SANDBOX.JWK,
        });
      default:
        return throwError(
          () => new Error(`Cannot find encryption key for SRC: ${srcName}`)
        );
    }
  }
}
