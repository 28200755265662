export enum RequestType {
  AUTH = "AUTH",
  WALLETVERIFY = "WALLETVERIFY",
  JSINIT = "JSINIT",
  THREEDQUERY = "THREEDQUERY",
  CACHETOKENISE = "CACHETOKENISE",
  ERROR = "ERROR",
  RISKDEC = "RISKDEC",
  SUBSCRIPTION = "SUBSCRIPTION",
  ACCOUNTCHECK = "ACCOUNTCHECK",
  THREEDLOOKUP = "THREEDLOOKUP",
}
