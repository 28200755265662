export enum ApplePayClientStatus {
  NO_ACTIVE_CARDS_IN_WALLET = "NO_ACTIVE_CARDS_IN_WALLET",
  CAN_MAKE_PAYMENTS_WITH_ACTIVE_CARD = "CAN_MAKE_PAYMENTS_WITH_ACTIVE_CARD",
  ON_VALIDATE_MERCHANT = "ON_VALIDATE_MERCHANT",
  VALIDATE_MERCHANT_SUCCESS = "VALIDATE_MERCHANT_SUCCESS",
  VALIDATE_MERCHANT_ERROR = "VALIDATE_MERCHANT_ERROR",
  ON_PAYMENT_AUTHORIZED = "ON_PAYMENT_AUTHORIZED",
  EMPTY_JWT_ERROR = "EMPTY_JWT_ERROR",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  SUCCESS = "SUCCESS",
  DECLINE = "DECLINE",
}
