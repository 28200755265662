export enum APMName {
  ACCOUNT2ACCOUNT = "ATA",
  ALIPAY = "ALIPAY",
  BANCONTACT = "BANCONTACT",
  BITPAY = "BITPAY",
  EPS = "EPS",
  GIROPAY = "GIROPAY",
  IDEAL = "IDEAL",
  MULTIBANCO = "MULTIBANCO",
  MYBANK = "MYBANK",
  PAYU = "PAYU",
  POSTFINANCE = "POSTFINANCE",
  PRZELEWY24 = "PRZELEWY24",
  REDPAGOS = "REDPAGOS",
  SAFETYPAY = "SAFETYPAY",
  SEPADD = "SEPADD",
  SOFORT = "SOFORT",
  TRUSTLY = "TRUSTLY",
  UNIONPAY = "UNIONPAY",
  WECHATPAY = "WECHATPAY",
  ZIP = "ZIP",
}
